import {defineStore} from "pinia/dist/pinia";

export const useEndpointStore = defineStore('EndpointStore', {
    state() {
        return {
            iaDomain: "ia-mcbs.esmo.org",
            uaDomain: "ua-mcbs.esmo.org",
            proDomain: "mcbs.esmo.org",
            iaBackend: "graphql.eks-uat.aws.esmo.org",
            uaBackend: "graphql.eks-uat.aws.esmo.org",
            proBackend: "graphql.eks-prod.aws.esmo.org"
        }
    },
    getters: {
        endpoint() {
            if (location.hostname === this.proDomain) {
                return "https://" + this.proBackend
            } else if (location.hostname === this.uaDomain) {
                return "https://" + this.uaBackend
            } else if (location.hostname === this.iaDomain) {
                return "https://" + this.iaBackend
            } else if (location.hostname.search("localhost") !== -1) {
                return "http://localhost:50001"
            }
            return "http://localhost:50001"
        }
    },
    actions: {}
})
