<template>
  <div class="pt-3 pb-3">

    <div class="col-12">
      <span class="text-sm-semi-bold purple-800-cl">Disclaimer:</span>
      <span class="text-sm-regular neutral-500"> Please note that the scores generated by this interactive tool have not undergone approval and validation by ESMO. All approved scores can be found in the
        <a class="purple-800-cl"
          href="https://www.esmo.org/guidelines/esmo-mcbs/esmo-mcbs-for-solid-tumours/esmo-mcbs-scorecards">ESMO-MCBS Scorecards</a>
      </span>
    </div>

    <div class="col-12">
      <span class="text-sm-semi-bold purple-800-cl">Terms of use:</span>
      <span class="text-sm-regular neutral-500"> Commercial institutions and organisations are prohibited from using or reproducing the ESMO-MCBS scores without written permission from ESMO. To enquire about permission, please send an email to <a
          class="purple-800-cl" href="mailto:publication_support@esmo.org">Publication Support</a>.</span>
    </div>

    <div class="pt-3 pl-3 pr-3">

      <div class="col-12 grid box-images">

        <div class="pt-3 pl-2 pr-2">
          <span class="text-xs-semi-bold neutral-black">Powered by</span>
        </div>

        <div class="pt-3 pl-2 pr-2">
          <img class="image" src="../public/images/Logo.png" alt="logo">
        </div>

        <div class="pt-3 pl-2 pr-2">
          <span class="text-xs-regular neutral-400">ESMO-Magnitude of Clinical Benefit Scale</span>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Footer"
})
</script>

<style scoped lang="scss">
.box-images {
  border-top: 1px solid #9FA5A9;
}
</style>