import {createApp, reactive} from 'vue';
import {i18n} from "./plugins/i18n";
import router from './router';
import axios from 'axios';
import moment from 'moment';
import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';
import VuePdfEmbed from 'vue-pdf-embed';
import {createGtm} from '@gtm-support/vue-gtm';
import * as PrimeVueConfig from './primevue';
import 'primevue/resources/primevue.min.css';
import 'font-awesome/css/font-awesome.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css'
import 'prismjs/themes/prism-coy.css';
import './assets/flags/language_flags.css';
import './assets/flags/country_flags.css';
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import {createPinia} from 'pinia/dist/pinia';
import {useUserStore} from "@/store/userStore";
import {useEndpointStore} from "@/store/endpointStore";
import 'primevue/resources/themes/lara-light-green/theme.css'


const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

const userLocale =
    navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
const languageNames = new Intl.DisplayNames([userLocale], {
    type: 'language'
});

const app = createApp(AppWrapper);
app.config.globalProperties.window = window
app.config.globalProperties.$appState = reactive({theme: 'esmo', darkTheme: false});
app.use(pinia);
app.use(PrimeVue, {ripple: true, inputStyle: 'outlined'});
app.use(router);
app.use(
    createGtm({
        id: 'GTM-WQRPKQFG',
        queryParams: {},
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        nonce: '2726c7f26c', // Will add `nonce` to the script tag
        enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        ignoredViews: [], // Don't trigger events for specified router names (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    }),
);
app.use(i18n);
app.component("vue-pdf-embed", VuePdfEmbed)

app.config.globalProperties.$methods = {
    isStandalone() {
        const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
        if (document.referrer.startsWith("android-app://")) {
            return true; // Trusted web app
        } else if ("standalone" in navigator || isStandalone) {
            return true;
        }
        return false;
    },
    isMobile() {
        return {
            Android: function () {
                return navigator.userAgent.match(/Android/i);
            },
            BlackBerry: function () {
                return navigator.userAgent.match(/BlackBerry/i);
            },
            iOS: function () {
                return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
            Opera: function () {
                return navigator.userAgent.match(/Opera Mini/i);
            },
            Samsung: function () {
                return navigator.userAgent.match(
                    /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
                );
            },
            Windows: function () {
                return (
                    navigator.userAgent.match(/IEMobile/i) ||
                    navigator.userAgent.match(/WPDesktop/i)
                );
            },
            any: function () {
                return (
                    this.Android() ||
                    this.BlackBerry() ||
                    this.iOS() ||
                    this.Opera() ||
                    this.Windows()
                );
            },
        }
    },
    publicPath() {
        if (module != null && module.exports != null && module.exports.publicPath != null) return module.exports.publicPath;
        return "/"
    },
    formatDate(value, format) {
        return formatDate(value, format)
    },
    deepClone(object) {
        if (object != null) {
            return JSON.parse(JSON.stringify(object))
        }
    },
    isEmpty(object) {
        return isEmpty(object)
    },
    sendToast(vue, errorKey) {
        if (errorKey != null) {
            vue.$toast.add({
                severity: vue.$t("common.toasts." + errorKey + ".severity"),
                summary: vue.$t("common.toasts." + errorKey + ".summary"),
                detail: vue.$t("common.toasts." + errorKey + ".detail"),
                life: 6000
            });
        }
    },
    suggestionByLabel(suggestions, value) {
        if (suggestions != null && value != null) {
            return suggestions.find(suggestion => {
                if (suggestion.label != null) {
                    return suggestion.label.trim() === (value.label != null ? value.label.trim() : value.trim())
                }
                return suggestion.trim() === (value.label != null ? value.label.trim() : value.trim())
            })
        }
        return null
    },
    savePdf(data, filename) {
        let link = window.document.createElement('a');
        link.href = "data:application/pdf;base64," + data;
        link.download = filename;
        link.click()
    }
}
PrimeVueConfig.init(app);

app.mount('#app');

export function getAxios(token) {
    const endpointStore = useEndpointStore()
    axios.defaults.baseURL = endpointStore.endpoint;
    axios.defaults.headers.common['x-api-key'] = 'edcbc106-f700-4589-9c8c-596bf84e8bba';
    if (token != null) {
        axios.defaults.headers.common.authorization = `Bearer ${token}`;
    }
    return axios;
}

export function executeQuery(token, query, variables) {
    return getAxios(token).post('', {query, variables}).then(result => {
        if (result.data.errors != null && result.data.errors.length > 0) {
            console.log(result.data.errors);
            let errorKey = null;
            result.data.errors.forEach(e => {
                if (e.exception != null && e.exception.errorKey != null) {
                    errorKey = e.exception.errorKey;
                }
            })
            throw {
                request: {status: 200},
                response: {data: {errorKey: errorKey != null ? errorKey : 'graphql'}}
            };
        }
        if (result.data.data != null) {
            return result.data.data[Object.keys(result.data.data)[0]];
        }
    }).catch(e => {
        console.error(`Error executing query\n${query}`, e);
        if (e.request.status === 403) {
            setUser(null);
        }
        throw e;
    });
}

export function setUser(user) {
    if (app != null && useUserStore != null) {
        const userStore = useUserStore()
        return userStore.setUser(user);
    }
}

export function getUser() {
    if (app != null && useUserStore != null) {
        const userStore = useUserStore()
        return userStore.user;
    }
}

export function languageName(code) {
    return languageNames.of(code).charAt(0).toUpperCase() + languageNames.of(code).slice(1)
}

export function createId(list) {
    if (list != null) {
        return -1 * (Math.max.apply(Math, list.map(function (o) {
            return o.id;
        })))
    }
    return -1
}

export function formatDate(value, format) {
    if (value && format) {
        return moment(value.toISOString()).format(format)
    }
}

export function isEmpty(object) {
    if (object == null || object === false || object === "" || object.length === 0 || typeof object === 'undefined' || object === {}) {
        return true;
    }
    if (typeof object === 'object' && Object.keys(object).length === 0) {
        return true;
    }
    return false;
}

