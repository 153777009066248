<template>
  <toast/>
  <div>
    <router-view/>
  </div>
  <div>
    <Footer/>
  </div>
</template>

<script>

import Footer from "@/Footer.vue";

export default {
  components: {
    'Footer': Footer,
  },
  data() {
    return {}
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss">

</style>
