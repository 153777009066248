import {createRouter, createWebHistory } from 'vue-router';
import {getUser} from './main'
import App from "@/App";

const routes = [
    { path: '/', redirect: '/scoringtool/categories' },
    { path: '/scoringtool', redirect: '/scoringtool/categories' },
    {
        path: '/scoringtool',
        component: App,
        children: [
            {
                path: '/scoringtool/categories',
                name: 'categories',
                exact: true,
                component: () => import('./components/Categories.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/study-information',
                name: 'studyInformation',
                exact: true,
                component: () => import('./components/StudyInformation.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/forms',
                name: 'forms',
                exact: true,
                component: () => import('./components/Forms.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/form-1',
                name: 'form1',
                exact: true,
                component: () => import('./components/forms/Form1.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/form-2a',
                name: 'form2a',
                exact: true,
                component: () => import('./components/forms/Form2a.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/form-2b',
                name: 'form2b',
                exact: true,
                component: () => import('./components/forms/Form2b.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/form-2c',
                name: 'form2c',
                exact: true,
                component: () => import('./components/forms/Form2c.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/form-3',
                name: 'form3',
                exact: true,
                component: () => import('./components/forms/Form3.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/scoring-form-1',
                name: 'scoringForm1',
                exact: true,
                component: () => import('./components/scorings/ScoringForm1.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/scoring-form-2a',
                name: 'scoringForm2a',
                exact: true,
                component: () => import('./components/scorings/ScoringForm2a.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/scoring-form-2b',
                name: 'scoringForm2b',
                exact: true,
                component: () => import('./components/scorings/ScoringForm2b.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/scoring-form-2c',
                name: 'scoringForm2c',
                exact: true,
                component: () => import('./components/scorings/ScoringForm2c.vue'),
                meta: {
                    requiresAuth: false
                }
            },
            {
                path: '/scoringtool/scoring-form-3',
                name: 'scoringForm3',
                exact: true,
                component: () => import('./components/scorings/ScoringForm3.vue'),
                meta: {
                    requiresAuth: false
                }
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory (),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (getUser() == null || getUser().token == null ) {
            next('/login')
            return
        }
    }
    next()
});

export default router;